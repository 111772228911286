<style>
    .item-deleted {
        background-color: #ffaeae;
    }
</style>

<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items="products"
                        :loading="loading"
                        class="elevation-1"

                        @pagination="update_page"
                        :server-items-length="total_products"
                        :items-per-page="items_per_page"
                        :page="page"
                        :item-class="item_background"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <router-link :to="'/products/create'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link>
                                    </div>
                                    <div class="col-md-4">
                                        <v-text-field
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        v-model="query"
                                        label="Search"
                                        class="mx-4"
                                        ></v-text-field>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:item.Url="{ item }">
                                <img :src="item.Url.split(',')[0]" style="width: 50px; height: 50px" />
                            </template>
                            <template v-slot:item.Category="{ item }">
                                {{ item.Category.Name }}
                            </template>
                            <template v-slot:item.Status="{ item }">
                                <v-icon v-if="item.Status == -1" left>mdi-check</v-icon>
                            </template>
                            <template v-slot:item.Description="{ item }" v-html="item.Description">
                                <div v-html="item.Description"></div>
                            </template>
                            <template v-slot:item.Tags="{ item }">
                                <v-chip v-for="e in item.Tags" :key="e.Id" class="ma-1">{{ e.Name }}</v-chip>
                            </template>
                            <template v-slot:item.Cost="{ item }">
                                {{ item.Cost.toLocaleString("vi-VN", {style: "currency", currency: "VND"}) }}
                            </template>
                            <template v-slot:item.Old_cost="{ item }">
                                {{ item.Old_cost.toLocaleString("vi-VN", {style: "currency", currency: "VND"}) }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <router-link :to="`/products/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link>
                                <v-icon small @click="confirm_delete(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Sản phẩm'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: this.$route.query.q || "",
            cate_id: this.$route.cate_id || -1,
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Hình ảnh", value: 'Url', sortable: false },
                { text: "Tên sản phẩm", value: 'Name', sortable: false },
                { text: "Category", value: 'Category', sortable: false },
                { text: "Từ khoá", value: 'Tags', sortable: false },
                { text: "Giá khuyến mại", value: 'Cost', sortable: false },
                { text: "Giá chưa khuyến mại", value: 'Old_cost', sortable: false },
                { text: "Đã xoá", value: 'Status', sortable: false },
                { text: "Actions", value: 'actions', sortable: false },
            ],

            
        };
    },
    watch: {
        options: {
            handler () {
                this.get_products();
            },
            deep: true,
        },
        query: {
            handler() {
                this.search_products();
            },
            deep: true
        }
    },
    computed: {
        products() {
            return this.$store.getters['products/get_products'];
        },
        total_products() {
            return this.$store.getters['products/get_total_products'];
        },
        page() {
            return this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        },
        items_per_page() {
            return this.$route.query.limit ? parseInt(this.$route.query.limit) : 15;
        }
    },
    components: {
    },
    methods: {
        get_products() {
            var self    = this;
            self.loading = true;
            const page  = this.$route.query.page || 1;
            const limit = this.$route.query.limit || 15;
            const q = this.query || "";
            const cate_id = this.cate_id || "";

            var payload = {
                pageSize: limit,
                pageIndex: page,
            };

            if(q) {
                payload.nameQuery = q;
            }
            if(cate_id) {
                payload.categoryId = cate_id;
            }

            this.$router.push({
                name: "products-index",
                query: { page, limit, q, cate_id }
            }).catch(() => { });

            this.$store.dispatch('products/get_all', payload).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        search_products() {
            const page  = 1;
            const limit = this.$route.query.limit || 15;
            const cate_id = this.cate_id;

            this.$router.push({
                name: "products-index",
                query: { page, limit, q: this.query, cate_id }
            }).catch(() => { });
            this.get_products();
        },
        confirm_delete(item) {
            var self = this;
            Swal.fire({
                title: `Xoá sản phẩm ${item.Name}?`,
                // text: `Xoá nhóm sản phẩm ${item.Name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(id) {
            this.$store.dispatch('products/delete_product', { id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_products();
            }).catch(() => {
                this.loading = false;
            });
        },
        update_page(pagination) {
            const page  = pagination.page;
            const limit = pagination.itemsPerPage;
            const q = this.query;
            const cate_id = this.cate_id;

            this.$router.push({
                name: "products-index",
                query: { page, limit, q, cate_id }
            }).catch(() => { });
            this.get_products();
        },
        item_background: function (item) {
            return item.Status == -1 ? 'item-deleted' : 'item-normal'
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Sản phẩm", route: "index" }, { title: "Tất cả" } ]);
        this.get_products();
    }
};
</script>

