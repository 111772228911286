var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"loading":_vm.loading,"server-items-length":_vm.total_products,"items-per-page":_vm.items_per_page,"page":_vm.page,"item-class":_vm.item_background},on:{"pagination":_vm.update_page},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col-md-4"},[_c('router-link',{staticClass:"btn btn-success font-weight-bold btn-sm",attrs:{"to":'/products/create'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm ")],1)],1),_c('div',{staticClass:"col-md-4"},[_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","label":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)])]},proxy:true},{key:"item.Url",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":item.Url.split(',')[0]}})]}},{key:"item.Category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Category.Name)+" ")]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(item.Status == -1)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.Description",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.Description)}})]}},{key:"item.Tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.Tags),function(e){return _c('v-chip',{key:e.Id,staticClass:"ma-1"},[_vm._v(_vm._s(e.Name))])})}},{key:"item.Cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Cost.toLocaleString("vi-VN", {style: "currency", currency: "VND"}))+" ")]}},{key:"item.Old_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Old_cost.toLocaleString("vi-VN", {style: "currency", currency: "VND"}))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/products/update/" + (item.Id))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirm_delete(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }